@use 'global' as *;

.p-column {
  &__tablecontents {
    padding: 2em;
    background: $color-gray-100;

    dt {
      @include font-size(36);
      font-weight: bold;
      margin-bottom: 1em;
    }

    dd {
      ol {
        list-style-position: inside;
        list-style-type: decimal;

        li {
          text-indent: -1em;
          padding-left: 1.2em;
          counter-increment: cnt;

          a::before {
            content: "" counter(cnt)"";
          }

          &+li {
            margin-top: 1em;
          }
        }
      }
    }
  }
}