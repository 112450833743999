@use 'global' as *;

.company {
  &-table {
    width: 100%;

    dl {
      line-height: 1.7;
      display: flex;
      border-bottom: 1px solid #D2D2D2;

      &:first-child {
        border-top: 1px solid #D2D2D2;
      }

      dt {
        width: 30%;
        min-width: 30%;
        font-weight: 700;
        padding: 1.5em 0.5em;
      }

      dd {
        padding: 1.5em 0.5em;
        flex: 1;
      }
    }

  }
}