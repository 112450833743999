@use '../global' as *;

.l-footer {
  color: #FFF;
  padding: 3em 20px 2em;
  background: #000;

  @include min-screen("md") {
    padding: 2em 40px;
  }

  &__inner {
    max-width: 1380px;
    margin: 0 auto;

    &__company {
      text-align: center;

      @include min-screen("md") {
        text-align: left;
      }

      img {
        width: 250px;

        @include min-screen("md") {
          width: 320px;
        }
      }
    }

    &__address {
      font-weight: bold;
      @include font-size(18);
      margin: 1.7em 0 1em;
      line-height: 1.3;
    }

    &__link {
      @include font-size(16);
      margin: 2em 0 1em;
      display: block;
      justify-content: flex-start;

      @include min-screen("md") {
        display: flex;
        justify-content: flex-end;
      }

      li {
        margin-left: 0;

        @include min-screen("md") {
          margin-left: 2em;
        }

        &+li {
          margin-top: 1em;

          @include min-screen("md") {
            margin-top: 0;
          }
        }

        a {
          display: flex;
          align-items: center;

          &:before {
            content: "";
            width: 0.5em;
            height: 0.5em;
            margin: 0 0.5em 0 0;
            display: inline-block;
            border-right: 2px solid #FFF;
            border-top: 2px solid #FFF;
            transform: rotate(45deg);
          }

        }

      }
    }

  }

  &__copyright {

    &-text {
      @include font-size(14);
      padding: 2em 0 0;
      text-align: center;
    }
  }

  &__pagetop {
    opacity: 0;
    transition-duration: 0.5s;
    transition-property: opacity, transform;
    position: fixed;
    right: 3%;
    bottom: 210px;

    @include min-screen("md") {
      bottom: 180px;
    }

    a {
      padding: 0.8em 0.7em 1em 0.7em;
      background: #FFF;
      display: block;
      border-radius: 50%;

      &:hover {
        opacity: 0.7;
        transition: all 0.3s;
      }

      img {
        width: 18px;

        @include min-screen("md") {
          width: 26px;
        }
      }
    }
  }
}

.is-scrolled .l-footer__pagetop {
  opacity: 1;

}

//l-footer