@use 'global' as *;

.company-scale {
  margin-bottom: 4em;
  text-align: center;
}

.company-scale .company-scale__map {
  margin: 2em 0 4em;
}

.company-scale__bottom {
  display: block;
  justify-content: space-between;

  @include min-screen("md") {
    display: flex;
  }
}

.company-scale__bottom-graph {
  width: 100%;
  margin-bottom: 2em;
  display: grid;

  @include min-screen("md") {
    width: 46%;
    margin-bottom: 0;
  }
}

.company-scale__bottom-graph-r-results {
  margin-top: auto;
}

.company-scale__bottom-graph-r-scale {
  margin: 0.3em 0 1.2em;

  img {
    width: 80%;

    @include min-screen("md") {
      width: 70%;
    }
  }
}