@use '../global' as *;

.l-header {
  background: #FFF;
  position: relative;
  z-index: 1003;

  &__inner {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include min-screen("md") {
      padding: 30px 40px;
    }

    &-logo {
      a {
        display: block;

        img {

          width: 57px;

          @include min-screen("md") {
            width: 100px;
          }

          @include min-screen(980px) {
            width: 160px;
          }

        }
      }
    }

    &-consultation {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include min-screen(980px) {
        margin-bottom: 2em;
      }

      &-text {
        color: $color-main-accent;
        font-weight: bold;
        margin-right: 1em;
        display: none;

        @include min-screen(980px) {
          display: block;
        }
      }

      &-button {
        font-weight: bold;
        text-align: center;

        >a {
          color: #FFF;
          padding: 0.5em 2em;
          background: $color-main-accent;
          display: block;
          border-radius: 3em;
        }

        &-contacttext {
          margin-bottom: 0.4em;
          @include font-size(11);

          @include min-screen("md") {
            @include font-size(15);
          }
        }

        &-tel {
          @include font-size(19);

          @include min-screen("md") {
            @include font-size(30);
          }

          font-family: $font-eng;

          img {
            width: 18px;
            margin-right: 0.2em;

            @include min-screen("md") {
              width: 30px;
            }
          }
        }


      }

    }

    &-navi {
      display: none;

      @include min-screen(980px) {
        display: block;
      }

      &-gnavi {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        li {
          margin-left: 3em;

          a {
            font-weight: bold;

            &:hover {
              opacity: 0.7;
              transition: all 0.7s;
            }

            &:before {
              content: "";
              width: 0.4em;
              height: 0.4em;
              margin: 0 0.4em 0.2em 0;
              display: inline-block;
              border-right: 2px solid $color-main-accent;
              border-bottom: 2px solid $color-main-accent;
              transform: rotate(45deg);
            }
          }
        }
      }
    }

  }

  .fullScreenMenu-button {
    display: block;

    @include min-screen(980px) {
      display: none;
    }
  }

}

//l-header