//font
.u-fw_n {
	font-weight: normal!important;
}
.u-fw_b {
	font-weight: bold!important;
}

.u-fz_xxl {
	font-size: 120%!important;
}
.u-fz_xl {
	font-size: 115%!important;
}
.u-fz_l {
	font-size: 110%!important;
}
.u-fz_s {
	font-size: 90%!important;
}
.u-fz_xs {
	font-size: 85%!important;
}
.u-fz_xxs {
	font-size: 80%!important;
}

//text
.u-td_n {
	text-decoration: none!important;
}
.u-td_u {
	text-decoration: underline!important;
}

.u-ta_l {
	text-align: left!important;
}
.u-ta_c {
	text-align: center!important;
}
.u-ta_r {
	text-align: right!important;
}

//margin-top
.u-mt_xxxl {
	margin-top: 50px!important;
}
.u-mt_xxl {
	margin-top: 45px!important;
}
.u-mt_xl {
	margin-top: 40px!important;
}
.u-mt_l {
	margin-top: 35px!important;
}
.u-mt_m {
	margin-top: 30px!important;
}
.u-mt_s {
	margin-top: 25px!important;
}
.u-mt_xs {
	margin-top: 20px!important;
}
.u-mt_xxs {
	margin-top: 15px!important;
}
.u-mt_xxxs {
	margin-top: 10px!important;
}
.u-mt_xxxxs {
	margin-top: 5px!important;
}

//margin-right
.u-mr_xxxl {
	margin-right: 50px!important;
}
.u-mr_xxl {
	margin-right: 45px!important;
}
.u-mr_xl {
	margin-right: 40px!important;
}
.u-mr_l {
	margin-right: 35px!important;
}
.u-mr_m {
	margin-right: 30px!important;
}
.u-mr_s {
	margin-right: 25px!important;
}
.u-mr_xs {
	margin-right: 20px!important;
}
.u-mr_xxs {
	margin-right: 15px!important;
}
.u-mr_xxxs {
	margin-right: 10px!important;
}
.u-mr_xxxxs {
	margin-right: 5px!important;
}

//margin-bottom
.u-mb_xxxl {
	margin-bottom: 50px!important;
}
.u-mb_xxl {
	margin-bottom: 45px!important;
}
.u-mb_xl {
	margin-bottom: 40px!important;
}
.u-mb_l {
	margin-bottom: 35px!important;
}
.u-mb_m {
	margin-bottom: 30px!important;
}
.u-mb_s {
	margin-bottom: 25px!important;
}
.u-mb_xs {
	margin-bottom: 20px!important;
}
.u-mb_xxs {
	margin-bottom: 15px!important;
}
.u-mb_xxxs {
	margin-bottom: 10px!important;
}
.u-mb_xxxxs {
	margin-bottom: 5px!important;
}

//margin-left
.u-ml_xxxl {
	margin-left: 50px!important;
}
.u-ml_xxl {
	margin-left: 45px!important;
}
.u-ml_xl {
	margin-left: 40px!important;
}
.u-ml_l {
	margin-left: 35px!important;
}
.u-ml_m {
	margin-left: 30px!important;
}
.u-ml_s {
	margin-left: 25px!important;
}
.u-ml_xs {
	margin-left: 20px!important;
}
.u-ml_xxs {
	margin-left: 15px!important;
}
.u-ml_xxxs {
	margin-left: 10px!important;
}
.u-ml_xxxxs {
	margin-left: 5px!important;
}

//ディスプレイ
.u-d_n {
	display:none!important;
}
.u-d_b {
	display:block!important;
}
.u-d_f {
	display:flex!important;
}
.u-d_if {
	display:inline-flex!important;
}
.u-d_i {
	display:inline!important;
}
.u-d_ib {
	display:inline-block!important;
}

//flexプロパティ
.u-jc_c {
	justify-content: center!important;
}
.u-jc_fe {
	justify-content:flex-end!important;
}
.u-jc_fs {
	justify-content:flex-start!important;
}
